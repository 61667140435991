/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import '~@angular/material/theming';
@import "./media-query.scss";

$custom-typography: mat.define-typography-config($font-family: "'Montserrat', sans-serif");

$custom-theme: mat.define-light-theme((typography: $custom-typography));

@include mat.core($custom-typography);
@include mat.all-component-themes($custom-theme);

$mat-our-red: (
  50: #F05154,
  100: #F05154,
  200: #F05154,
  300: #F05154,
  400: #F05154,
  500: #F05154,
  600: #F05154,
  700: #F05154,
  800: #F05154,
  900: #F05154,
  A100: #F05154,
  A200: #F05154,
  A400: #F05154,
  A700: #F05154,
  contrast: (
    50: $light-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $light-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$primary-palette: mat-palette($mat-our-red);
$secondary-palette: mat-palette($mat-grey);
$warn-palette: mat-palette($mat-red);

$light-theme: mat-light-theme($primary-palette, $secondary-palette, $warn-palette);

@include angular-material-theme($light-theme);


html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;

    touch-action: manipulation;
}

p {
    margin: 0;
}

button {
    font-family: 'Montserrat', sans-serif;
}

a, a:hover, a:focus, a:active {
    text-decoration: none; color: inherit;
}

.mat-menu-panel {
    max-width: 1000px !important;
}

.generic-loading-container {
    display: grid;
    height: 100%;
    align-items: center;
    justify-content: center;
    justify-self: center;
    align-self: center;

    .loading-spinner {
        margin: 0 auto;

        $loading-size: 20vmin;
    
        width: $loading-size !important;
        height: $loading-size !important;
    
        svg {
            width: $loading-size !important;
            height: $loading-size !important;
        }
    }

    .loading-text {
        text-align: center;
        margin-top: 5vmin;
        font-size: 3vmin;
    }
}

.page-title {
    text-align: center;
    line-height: 1em;
    

    @media (max-width: $tablet-max-width) {
        margin: 0.5em 0;
        font-size: 3em;
        padding: 0 10px;
    }

    @media (min-width: $laptop-min-width) {
        margin: 2vw 0;
        font-size: 3vw;
    }
}

.page-subtitle {
    text-align: center;
    line-height: 1em;
    
    @media (max-width: $tablet-max-width) {
        margin-bottom: 1em;
        font-size: 1.75em;
    }

    @media (min-width: $laptop-min-width) {
        margin-bottom: 1vw;
        font-size: max(1.75em, 1.5vw);
    }
}

.invisible-backdrop {
    opacity: 0;
}

.gradient-button {
    border: none;

    &[disabled] {
        background: lightgray;
        color: gray;
        cursor: default;
    }

    &:not([disabled]) {
        background: linear-gradient(90deg, #f05154 0%, #e86f3C 100%);
        color: white;
        cursor: pointer;
    }
}

.mrkresz-gradient-background {
    background: #f05154;
    background: linear-gradient(120deg, #f05154 0%, #e86f3C 100%);
}

.mrkresz-gray-background {
    background: #d3d3d3;
}

.mat-dialog-container {
    padding: 0 !important;
}

// Button loading

@keyframes button-loading-animation {
    to {transform: rotate(360deg);}
}
 
.button-loading:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: button-loading-animation .8s linear infinite;
}